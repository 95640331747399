import store from "@/store";

export default class DataManager {
	constructor() {
		this.getters = store.getters
	}

	findGames() {
		var data = []
		this.getters.games.forEach((game) => {
			game.games.forEach((i) => {
				data.push(i)
			})
		})
		return data
	}
	findGamesType(gid) {
		var data = {}
		this.getters.games.forEach((game) => {
			game.games.forEach((g) => {
				if (parseInt(gid) === parseInt(g.gameId)) {
					data = game.type
				}
			})
		})
		return data
	}

	findGameByName(type) {
		var data = {}
		this.getters.games.forEach((game) => {
			game.games.forEach((g) => {
				if (type === g.name) {
					data = g
				}
			})
		})
		return data
	}

	findGameByGid(gid) {
		var data = {}
		this.getters.games.forEach((game) => {
			game.games.forEach((g) => {
				if (parseInt(gid) === parseInt(g.gameId)) {
					data = g
				}
			})
		})
		return data
	}

	findGamesByType(type) {
		var data = {}
		this.getters.games.forEach((game) => {
			if (type === game.type) {
				data = game
			}
		})
		return data
	}

	getDevice() {
		var res = ''
		switch (this.getOS()) {
			case 'Mac OS':
			case 'Windows':
			case 'Linux':
				res = 'desktop'
				break;
			// case 'Windows':
			case 'iOS':
			case 'Android':
				res = 'mobile'
				break;
		}
		return res
	}

	getOS() {
		// var osna = "Unknown";
		// if (navigator.appVersion.indexOf("Windows")!=-1) osna="Windows";
		// if (navigator.appVersion.indexOf("Mac")!=-1) osna="OSX";
		// if (navigator.appVersion.indexOf("X11")!=-1) osna="Unix";
		// if (navigator.appVersion.indexOf("Linux")!=-1) osna="Linux";
		// return osna;

		var userAgent = window.navigator.userAgent,
			platform = window.navigator.platform,
			macosPlatforms = ['Macintosh',  'MacPPC', 'Mac68K'],
			windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
			iosPlatforms = ['MacIntel','iPhone', 'iPad', 'iPod'],
			os = null;

		if (userAgent.indexOf('Macintosh') !== -1 || macosPlatforms.indexOf(platform) !== -1) {
			os = 'Mac OS';
		} else if (iosPlatforms.indexOf(platform) !== -1) {
			os = 'iOS';
		} else if (windowsPlatforms.indexOf(platform) !== -1) {
			os = 'Windows';
		} else if (/Android/.test(userAgent)) {
			os = 'Android';
		} else if (!os && /Linux/.test(platform)) {
			os = 'Linux';
		}


		return os;
	}
}
