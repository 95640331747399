const setting = require('../utils/config.js');

let initialState = {}

initialState = {
  copyright:setting.copyright,
  navList:{
    headerNavList: setting.headerNavList,
    footerNavList: setting.footerNavList,
  },
  games: setting.games,
  accounts: setting.accounts,

}

export const state = initialState
