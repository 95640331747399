const SETTING = {
	language: ['tw', 'en'],
	copyright: '<span>2021 © Arcadia Gaming Inc.All Rights Reserved.</span>',
	miniGameUrl: 'https://www-s3.card168.cc/cocos_minigames/Arion-Dev/develop/web-mobile/index.html',
	headerNavList: [
		{
			name: 'Home',
			route: 'Home'
		},
		{
			name: 'About Us',
			route: 'AboutUs'
		},
	],
	footerNavList: [
		// {
		//   name: 'Home',
		//   route: 'Home'
		// },
		{
			name: 'About Us',
			route: 'AboutUs'
		},
	],
	games: [
		{
			name: 'MINI GAME',
			type: 'minigame',
			games: [
				{
					name: 'bigsmall',
					login: {
						type: 'random',
					},
					size: {
						desktop: {width: 448, height: 410},
						mobile: {width: 720, height: 340}
					},
					bgColor: 'rgba(23,0,47,1)',
					btnTextColor: 'rgba(255,255,255,1)',
					gameId: 33,
					linkUrl: 'https://minigame.agsgames.xyz/',
					info: {
						gameType: 'minigame',
						fullSize: {
							number: '3.2',
							type: 'MB'
						},
						inital: {
							number: '3.2',
							type: 'MB'
						},
						compatible: ['apple', 'android', 'windows', 'html5'],
						language: ['en', 'cn', 'th', 'vi', 'id'],
					},
					description: 'Ditch the boring casino games! Arcadia “Big Small” is a simplified traditional lotto game with eye-catching graphics and fast gameplay.<br/>Players can simply choose from 2 bet types, namely: Big or Small. Big wagers win if the result of the lotto ball is 1,2 or 3 and Small wagers win if the result of the lotto ball is 4, 5 or 6.'
				},
				{
					name: 'oddeven',
					login: {
						type: 'random',
					},
					size: {
						desktop: {width: 448, height: 410},
						mobile: {width: 720, height: 340}
					},
					bgColor: 'rgba(17,0,109,1)',
					btnTextColor: '#ffffff',
					gameId: 34,
					linkUrl: 'https://minigame.agsgames.xyz/',
					info: {
						gameType: 'minigame',
						fullSize: {
							number: '3.4',
							type: 'MB'
						},
						inital: {
							number: '3.4',
							type: 'MB'
						},
						compatible: ['apple', 'android', 'windows', 'html5'],
						language: ['en', 'cn', 'th', 'vi', 'id'],
					},
					description: 'Experience a new style of gaming!<br/>Arcadia “Odd Even” is a simple but refreshing wager game with awesome graphics and smooth gameplay. Players can simply place their bets on Odd or Even and win accordingly.'
				},
				{
					name: '11hilo',
					login: {
						type: 'random',
					},
					size: {
						desktop: {width: 448, height: 410},
						mobile: {width: 720, height: 340}
					},
					bgColor: 'rgba(103,49,9,1)',
					btnTextColor: '#723e05',
					gameId: 35,
					linkUrl: 'https://minigame.agsgames.xyz/',
					info: {
						gameType: 'minigame',
						fullSize: {
							number: '6.0',
							type: 'MB'
						},
						inital: {
							number: '6.0',
							type: 'MB'
						},
						compatible: ['apple', 'android', 'windows', 'html5'],
						language: ['en', 'cn', 'th', 'vi', 'id'],
					},
					description: 'Have fun with our new innovative game! Arcadia “11HiLo” is a quick and easy dice game that will give you new excitement.<br/>There are 3 options for players to place their wagers: HI, LO or 11. “HI” wagers win if the result is 12-18 and “LO” wagers win if the result is 3-10. A high payout will be awarded to players who wagers on “11” and hits it!'
				},
				{
					name: 'baccarat',
					login: {
						type: 'random',
					},
					size: {
						desktop: {width: 448, height: 410},
						mobile: {width: 720, height: 340}
					},
					bgColor: 'rgba(0,0,0,1)',
					btnTextColor: '#ffffff',
					gameId: 37,
					linkUrl: 'https://minigame.agsgames.xyz/',
					info: {
						gameType: 'minigame',
						fullSize: {
							number: '4.1',
							type: 'MB'
						},
						inital: {
							number: '4.1',
							type: 'MB'
						},
						compatible: ['apple', 'android', 'windows', 'html5'],
						language: ['en', 'cn', 'th', 'vi', 'id'],
					},
					description: 'Play Baccarat like 007!<br/>Forget the boring traditional Baccarat games. Introducing Arcadia “Baccarat” with a unique 007 theme with a specially designed Bonus Shot feature that gives you extraordinary payout if you hit the correct result.'
				},
				{
					name: 'rps',
					login: {
						type: 'random',
					},
					size: {
						desktop: {width: 448, height: 410},
						mobile: {width: 720, height: 340}
					},
					bgColor: 'rgba(148,61,36,1)',
					btnTextColor: '#203171',
					gameId: 38,
					linkUrl: 'https://minigame.agsgames.xyz/',
					info: {
						gameType: 'minigame',
						fullSize: {
							number: '3.4',
							type: 'MB'
						},
						inital: {
							number: '3.4',
							type: 'MB'
						},
						compatible: ['apple', 'android', 'windows', 'html5'],
						language: ['en', 'cn', 'th', 'vi', 'id'],
					},
					description: 'Take a stroll down memory lane!<br/>Relive the good old days with a game of Arcadia “Rock Paper Scissors”! Join in the heads up fight between Lil’ Puppy and Kitty by wagering on your prediction of the winner or a tie game!'
				},
				{
					name: 'luckydash',
					login: {
						type: 'random',
					},
					size: {
						desktop: {width: 448, height: 410},
						mobile: {width: 720, height: 340}
					},
					bgColor: 'rgba(24,139,238,1)',
					btnTextColor: '#ff5500',
					gameId: 39,
					linkUrl: 'https://minigame.agsgames.xyz/',
					info: {
						gameType: 'minigame',
						fullSize: {
							number: '3.6',
							type: 'MB'
						},
						inital: {
							number: '3.6',
							type: 'MB'
						},
						compatible: ['apple', 'android', 'windows', 'html5'],
						language: ['en', 'cn', 'th', 'vi', 'id'],
					},
					description: 'And those who are brave shall choose Roulette, or perhaps Roulette shall choose them!<br/>Arcadia “Lucky Dash” is a new innovative game with delightful graphics based on traditional roulette games. Make a guess on which color Lily will land on! Oh and don\'t forget there are special lucky numbers in each game. Extra payout odds will be awarded If Lily finds the lucky number!'
				},
				{
					name: 'goal!!!',
					login: {
						type: 'random',
					},
					size: {
						desktop: {width: 448, height: 410},
						mobile: {width: 720, height: 340}
					},
					bgColor: 'rgba(9,65,112,1)',
					btnTextColor: '#fffffff',
					gameId: 40,
					linkUrl: 'https://minigame.agsgames.xyz/',
					info: {
						gameType: 'minigame',
						fullSize: {
							number: '6.2',
							type: 'MB'
						},
						inital: {
							number: '6.2',
							type: 'MB'
						},
						compatible: ['apple', 'android', 'windows', 'html5'],
						language: ['en', 'cn', 'th', 'vi', 'id'],
					},
					description: 'Goal!!! is a super exciting football penalty game.<br/>There are 8 different betting types!<br/>Can the goalkeeper prevent the shot from entering the net? Or it fly straight into the net?<br/>Join us and guess the result!'
				},
				{
					name: 'dragon tiger',
					login: {
						type: 'random',
					},
					size: {
						desktop: {width: 448, height: 410},
						mobile: {width: 720, height: 340}
					},
					bgColor: 'rgba(0,0,0,1)',
					btnTextColor: '#ffffff',
					gameId: 41,
					linkUrl: 'https://minigame.agsgames.xyz/',
					info: {
						gameType: 'minigame',
						fullSize: {
							number: '6.9',
							type: 'MB'
						},
						inital: {
							number: '6.9',
							type: 'MB'
						},
						compatible: ['apple', 'android', 'windows', 'html5'],
						language: ['en', 'cn', 'th', 'vi', 'id'],
					},
					description: 'Dragon tiger is a classic poker game!<br/>Guess who is the king of monsters by comparing cards!<br/>In addition, there are bonus three links, you can get extra odds!'
				},
				{
					name: 'blackjack',
					login: {
						type: 'random',
					},
					size: {
						desktop: {width: 448, height: 410},
						mobile: {width: 720, height: 340}
					},
					bgColor: 'rgba(0,0,0,1)',
					btnTextColor: '#000000',
					gameId: 42,
					linkUrl: 'https://minigame.agsgames.xyz/',
					info: {
						gameType: 'minigame',
						fullSize: {
							number: '7.5',
							type: 'MB'
						},
						inital: {
							number: '7.5',
							type: 'MB'
						},
						compatible: ['apple', 'android', 'windows', 'html5'],
						language: ['en', 'cn', 'th', 'vi', 'id'],
					},
					description: 'Blackjack is a common game in casino. The player\'s goal is to get the points closest to 21 points, but not more than 21 points, and then compare the size with the dealer. The game is easy to use, we also increase the "sidebet" playing method, which can obtain a maximum of 40 times the odds!'
				},
				{
					name: 'flipz',
					login: {
						type: 'random',
					},
					size: {
						desktop: {width: 448, height: 410},
						mobile: {width: 720, height: 340}
					},
					bgColor: 'rgba(0,0,0,1)',
					btnTextColor: 'rgba(255,255,255,1)',
					gameId: 43,
					linkUrl: 'https://minigame.agsgames.xyz/',
					info: {
						gameType: 'minigame',
						fullSize: {
							number: '4.7',
							type: 'MB'
						},
						inital: {
							number: '4.7',
							type: 'MB'
						},
						compatible: ['apple', 'android', 'windows', 'html5'],
						language: ['en', 'cn', 'th', 'vi', 'id'],
					},
					description: 'BTC and ETH, guess who is the king of cryptocurrency?<br/>A round of 20 seconds is too long?<br/>There are too many rules. You don\'t know how to play?<br/>Why don\'t you play FLIPZ ?<br/>Win or lose in 3 seconds. <br/>Here is a simple and enjoyable game.'
				},
			]
		},
		{
			name: 'marble game',
			type: 'marblegame',
			games: [
				{
					name: 'marble mini',
					login: {
						type: 'random',
					},
					size: {
						mobile: {width: 340, height: 720}
					},
					bgColor: 'rgba(0,184,235,1)',
					btnTextColor: 'rgba(255,255,255,1)',
					gameId: 36,
					linkUrl: 'https://marblemini.agsgames.xyz/',
					info: {
						gameType: 'minigame',
						fullSize: {
							number: '14.1',
							type: 'MB'
						},
						inital: {
							number: '14.1',
							type: 'MB'
						},
						compatible: ['apple', 'android', 'windows', 'html5'],
						language: ['en', 'cn', 'th', 'vi', 'id'],
					},
					description: 'Marble Mini is a online marble races where marbles will race through obstacles and fight for the first place.<br/>There are 4 characters in this marble race, let see whether you can guess it right on which of these 4 characters can reach the end point first!'
				}]
		},
		{
			name: 'lotto game',
			type: 'lottogame',
			games: [
				{
					name: 'lotto world',
					login: {
						type: 'account',
					},
					size: {
						mobile: {width: 340, height: 720}
					},
					bgColor: 'rgba(23,0,47,1)',
					btnTextColor: 'rgba(255,255,255,1)',
					gameId: 0,
					linkUrl: 'https://lottoworld.agsgames.xyz/',
					info: {
						gameType: 'minigame',
						fullSize: {
							number: '13',
							type: 'MB'
						},
						inital: {
							number: '13',
							type: 'MB'
						},
						compatible: ['apple', 'android', 'windows', 'html5'],
						language: ['en', 'cn', 'th', 'vi', 'id'],
					},
					description: 'Lotto World is a fast pacing lottery game with bet types that are different from the ordinary lottery.<br/>Join now and experience a brand new way of fun and excitement in our Lotto world!'
				}]
		},
		{
			name: 'aresbet game',
			type: 'aresbetgame',
			games: [
				{
					name: 'ares bet',
					login: {
						type: 'random',
					},
					size: {
						mobile: {width: 720, height: 340}
					},
					bgColor: 'rgba(0,0,0,1)',
					btnTextColor: 'rgba(255,255,255,1)',
					gameId: 201,
					linkUrl: 'https://aresbet.agsgames.xyz/',
					info: {
						gameType: 'aresbetgame',
						fullSize: {
							number: '14',
							type: 'MB'
						},
						inital: {
							number: '7',
							type: 'MB'
						},
						compatible: ['apple', 'android', 'windows', 'html5'],
						language: ['en', 'cn'],
					},

					description: 'Baccarat, has 16 of the most popular bet types in the market, also provides you with \'NO COMMISSION \' option.'
				}]
		},
		{
			name: 'aeonbet game',
			type: 'aeonbetgame',
			games: [
				{
					name: 'aeon bet',
					login: {
						type: 'random',
					},
					size: {
						mobile: {width: 720, height: 340}
					},
					bgColor: 'rgba(0,0,0,1)',
					btnTextColor: 'rgba(255,255,255,1)',
					gameId: 71,
					linkUrl: 'https://aeonbet.agsgames.xyz/',
					info: {
						gameType: 'aeonbetgame',
						fullSize: {
							number: '10.7',
							type: 'MB'
						},
						inital: {
							number: '10.7',
							type: 'MB'
						},
						compatible: ['apple', 'android', 'windows', 'html5'],
						language: ['en', 'cn', 'zh'],
					},

					description: 'AEON BET adopts the latest technology to provide you with a friendly and concise operation interface, verifiable fairness mechanism per round and more betting types. Still playing old age games? Come and try the latest game of AEON BET!'
				}]
		}
	],
	accounts: [
		{
			acc: "demopt01",
			pwd: "aaaa1111"
		},
		{
			acc: "demopt02",
			pwd: "aaaa1111"
		},
		{
			acc: "demopt03",
			pwd: "aaaa1111"
		},
		{
			acc: "demopt04",
			pwd: "aaaa1111"
		},
		{
			acc: "demopt05",
			pwd: "aaaa1111"
		},
		{
			acc: "demopt06",
			pwd: "aaaa1111"
		},
		{
			acc: "demopt07",
			pwd: "aaaa1111"
		},
		{
			acc: "demopt08",
			pwd: "aaaa1111"
		},
		{
			acc: "demopt09",
			pwd: "aaaa1111"
		},
		{
			acc: "demopt10",
			pwd: "aaaa1111"
		}
	]
}
module.exports = SETTING
