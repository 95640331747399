import axios from 'axios'
import api from '@/utils/_api'
// import popup from '@/components/popup/index.js'

const Axios = axios.create({
	config: {
		headers: {
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Headers': 'Content-Type'
		}
	}
});
Axios.interceptors.response.use(response => {
	const {data} = response;
	if (data.err) {
		throw data.err;
	}
});

export const loadAni = (getters, {credentials}) => {

	var url = 'json/animation/'+credentials.type+'/' + credentials.file + '.json'
	return axios.get(
		url,
		'data.json'
	).then(res => {
		return res
	})
}

export const loadGameAni = (getters, {credentials}) => {
	if (credentials.game == 'mini') {
		var url = 'json/animation/game/' + credentials.game + '/' + credentials.name + '/trygame_' + credentials.type + '.json'
	} else {
		var url = 'json/animation/game/' + credentials.game + '/trygame_' + credentials.type + '.json'
	}
	return axios.get(
		url,
		'data.json'
	).then(res => {
		return res
	})
}
