import axios from 'axios'


const Axios = axios.create({
    config: {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Content-Type'
        }
    }
});
Axios.interceptors.response.use(response => {
    // const {data} = response.data;
    if (response.data.errCode > 0) {
        throw response.data.errCode;
    }
    return response
});

export default {

    loadGameAni({credentials}) {

        var url = 'json/animation/game/marble mini/trygame_'+ credentials+'.json'
        return Axios.post(
            url
        ).then(res => {
            return res
        })
    },
}

