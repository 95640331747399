import Vue from 'vue'
import App from './App.vue'
import store from './store'
import eventBus from '@/eventBus'
import dataManager from '@/utils/dataManager'
import Alert from '@/components/Layout/alert'

Vue.use(Alert);
Vue.prototype.$dataManager = new dataManager()
Vue.config.productionTip = false

import(`@/router/`).then(module => {
  let router = module.default;

  window.vue = new Vue({
    data() {
      return {
        // Bind our event bus to our $root Vue model
        bus: eventBus,
      }
    },
    computed: {

    },
    watch: {
    },
    mounted() {
      window.$alert = this.$alert;
    },
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
})

