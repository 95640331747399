export const copyright = state => state.copyright
export const navList = state => state.navList
export const games = state => state.games
export const accounts = state => state.accounts

// export const auth = (state) => {
//     return state.auth || {}
// }


